<template>
  <div class="editor-detail other-height">
    <div class="page-title">
      <div class="container">
        <div class="con-title con-title-white">
          <span>详情</span>
        </div>
      </div>
    </div>
    <div class="con-item">
      <div class="container container-details">
        <div class="container-left beauty-scroll">
          <h3 style="font-size:20px">{{ editorName }}</h3>
          <p class="color-red">{{ editorPosition }}</p>
          <div
            class="class-buffer ql-container ql-snow font16"
          >
            <div class="brief-introduction ql-editor">
              <div class="brief-introduction-content" v-html="digest"></div>
            </div>
          </div>
        </div>
        <div class="container-right">
          <p style="font-size:22px">代表作品：</p>
          <div class="list">
            <a-list item-layout="vertical" :data-source="dataEditor">
              <a-list-item slot="renderItem" key="item.title" slot-scope="item">
                <img
                  slot="extra"
                  alt="pic"
                  v-if="!item.sysFldCoverpath"
                  @click="gobookDetails(item.sysFldDoi)"
                  :src="require('@/assets/images/nobook.jpeg')"
                />
                <img
                  slot="extra"
                  alt="pic"
                  v-if="item.sysFldCoverpath"
                  @click="gobookDetails(item.sysFldDoi)"
                  :src="item.imgurl"
                />
                <a-list-item-meta>
                  <template slot="title"><span :title="item.name" class="representative-work-title" @click="gobookDetails(item.sysFldDoi)">{{ item.name }}</span></template>
                  <a slot="description" @click="gobookDetails(item.sysFldDoi)">
                    <p :title="item.author">作者：{{ item.author }}</p>
                    <p>出版时间：{{ item.issuedate }}</p>
                    <p>
                      <a-button class="representative-work-btn" type="primary" @click="gobookDetails(item.sysFldDoi)"
                        >详情</a-button
                      >
                    </p>
                  </a>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
            <div class="page-other">
              <a-pagination
                show-quick-jumper
                v-show="total > 6"
                v-model="current"
                :pageSize="6"
                :total="total"
                @change="onChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/editorIndex.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "editorDetails",
  components: {},
  data() {
    return {
      editorName: "",
      editorPosition: "",
      digest: "",
      dataEditor: [],
      current: 1,
      total: 0,
    };
  },
  created() {
    //查询编辑详情
    this.getContent();
  },
  methods: {
        //去图书详情
    gobookDetails(doi) {
      this.$router.push({
        name: "BookDetail",
        params: {
          doi: doi,
        },
      });
    },
    onChange(number) {
      this.current = number;
      this.getContent();
    },
    getContent() {
      api
        .getEditorInfo({
          doi: this.$route.params.doi,
          pageNumber: this.current,
          pageSize: 6,
        })
        .then((res) => {
          if (res.data.success) {
            this.editorName = res.data.map.editorInfo
              ? res.data.map.editorInfo.name
              : "";
            this.editorPosition = res.data.map.editorInfo
              ? res.data.map.editorInfo.position
              : "";
            this.digest = res.data.map.editorInfo
              ? res.data.map.editorInfo.digest
              : "";

            this.dataEditor = res.data.data.list;
            this.dataEditor.map(function (item) {
              item.imgurl =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldCoverpath;
            });
            this.total = res.data.data.count;
          } else {
            this.$message.error("查询失败！");
          }
        });
    },
  },
};
</script>
<style lang="less">
@import "./editorDetails.less";
@import "../QuillEditor/quill-editor.less";
</style>
